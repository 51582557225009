<template>
  <el-dialog v-model="visible" width="65%" :before-close="backtrackNotUpdate">
    <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 10px 0  10px;">
      <!-- <el-form-item label="门店">
        <el-select v-model="data.storeCode" clearable>
          <el-option v-for="item in storeList" :label="item.label" :key="item.value" :value="item.value"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="优惠方案 ">
        <el-select v-model="data.type" clearable>
          <el-option v-for="item in data.types" :label="item.label" :key="item.code" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优惠劵名称 " v-if="needFilterStore">
        <el-input v-model="data.couponRuleName" clearable></el-input>
      </el-form-item>
      <el-form-item v-if="needFilterStore">
        <el-checkbox v-model="data.checked" label=" 仅看门店匹配优惠券" />
      </el-form-item>
      <el-form-item>
        <el-button @click="selectBelowProductSkuArray({ currentPage: 1 })">查询</el-button>
        <el-button @click="backtrack">取消</el-button>
        <el-button @click="onsubmit" type="primary">确定</el-button>
      </el-form-item>
    </el-form>

    <el-empty
      description="暂无数据"
      :image-size="100"
      v-if="data.skuListsSelect.length === 0 && !data.productName && !data.categoryCode"
    />
    <div
      v-if="data.skuListsSelect.length !== 0 || data.productName || data.categoryCode"
      style="display: flex; flex-wrap: wrap; max-height: 600px; overflow-y: auto;"
    >
      <el-table :data="data.skuListsSelect" style="width: 100%" height="480" ref="elTable">
        <el-table-column align="center" width="50">
          <template #header>
            <el-checkbox v-model="data.checkAll" @change="handleCheckAllChange"></el-checkbox>
          </template>
          <template #default="{row}">
            <el-checkbox v-model="row.checked" @change="handleCheckAllOne(row)" />
          </template>
        </el-table-column>
        <el-table-column label="序号" align="center" type="index" :index="indexfn" width="100" />
        <el-table-column show-overflow-tooltip label="名称" prop="name" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip label="优惠方案" align="center" prop="type">
          <template #default="{row}">
            {{ data.typesObj[row.type] }}
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip align="center" label="优惠值">
          <template #default="{row}"> {{ row.scheme }}{{ data.unitsObj[row.type] }} </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip align="center" label="有效期" prop="validityTimeStr"></el-table-column>
        <el-table-column show-overflow-tooltip align="center" label="发放数量" prop="realIssueNum">
          <template #default="scope">
            {{ scope.row.issueNum === -1 ? '不限制' : scope.row.realIssueNum }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip align="center" label="节假日不可用" prop="">
          <template #default="{row}">
            {{ row.isHolidaysExcepted === 0 ? '可用' : '不可用' }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="适用门店" align="center">
          <template #default="{row}">
            <span v-if="row.attemptStore == -1">全部门店</span>
            <span v-else style="color:rgb(0, 217, 255);cursor: pointer;" @click="showStore(row)">指定门店</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
      <el-pagination
        v-if="data.skuListsSelect.length !== 0"
        v-model:current-page="data.currentPage"
        v-model:page-size="data.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="data.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, toRaw, onMounted, watchEffect } from 'vue'
import API from '../service/api'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
    units: Array,
    channelList: Array,
    toastMsg: {
      type: String,
      default: '已添加该优惠劵',
    },
    isAll: {
      type: Boolean,
      default: false,
    },
    needFilterStore: {
      type: Boolean,
      default: false,
    },
    storeCodeList: {
      type: Array,
      default: () => [],
    },
    isFixedStore: {
      type: Number,
      default: 1,
    },
    onlySelectOne: {
      type: Boolean,
      default: false,
    },
    hasPro: {
      type: Array,
      default: [],
    },
  },
  setup(props, ctx) {
    const data = reactive({
      checkAll: false,
      categoryCode: undefined,
      productName: undefined,
      currentPage: 1,
      pageSize: 10,
      total: 100,
      selectLists: [],
      skuLists: [],
      skuListsSelect: [],
      hasSelectSkuList: [],
      type: '',
      couponRuleName: '',
      checked: true,
      typesObj: {
        1: '折扣',
        2: '抵金额',
        3: '抵时长',
        4: '优惠价',
      },
      types: [
        {
          code: 1,
          label: '折扣',
        },
        {
          code: 2,
          label: '抵金额',
        },
        {
          code: 3,
          label: '抵时长',
        },
        {
          code: 4,
          label: '优惠价',
        },
      ],
      unitsObj: {
        1: '折',
        2: '元',
        3: '分钟',
        4: '元',
      },
    })
    /**
     * 展示指定门店信息
     */
    const showStore = data => {
      ElNotification({
        title: '指定的门店',
        message: `当前包含的门店有：${data.storeNames ? data.storeNames : '苏州店，厦门店，上海店'}`,
        duration: 0,
      })
    }

    const visible = computed(() => {
      return props.dialogVisible
    })
    const elTable = ref(null)

    const indexfn = computed(() => {
      return (data.currentPage - 1) * data.pageSize + 1
    })

    onMounted(() => {
      selectBelowProductSkuArray({})
    })

    watchEffect(() => {
      data.checkAll = false
      let start = (data.currentPage - 1) * data.pageSize
      let end = data.currentPage * data.pageSize

      let newArr = data.skuLists.slice(start, end)
      data.skuListsSelect = [...newArr]
    })

    const selectBelowProductSkuArray = ({ currentPage }) => {
      if (currentPage) {
        data.currentPage = currentPage
      }
      let params = {
        pageSize: 10000,
        pageNum: 1,
        type: data.type,
        isContanceCancel: 0,
        couponRuleName: data.couponRuleName,
        isOnlyAll: props.isFixedStore === 1 || !data.checked ? 0 : 1,
      }
      if (props.needFilterStore) {
        params.storeCodeList = data.checked ? props.storeCodeList : []
      }
      data.hasSelectSkuList = data.hasSelectSkuList.length == 0 ? props.hasPro : data.hasSelectSkuList

      API.selectCouponPage(params).then(res => {
        if (res.code === '200') {
          if (props.isAll) {
            res.data.records = res.data.records.map(item => ({
              ...item,
            }))
          } else {
            res.data.records = res.data.records
              .filter(item => item.termOfValidity !== 1)
              .map(item => ({
                ...item,
              }))
          }
          res.data.records.forEach(a => {
            let b = data.hasSelectSkuList.find(b => b.code == a.code)
            if (b) {
              a.checked = true
            } else {
              a.checked = false
            }
          })
          data.skuLists = res.data.records
          data.total = res.data.records.length
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const handleCheckAllChange = () => {
      data.skuListsSelect.forEach(element => {
        element.checked = data.checkAll
        handleCheckAllOne(element)
      })
    }

    const handleCheckAllOne = row => {
      let b = data.hasSelectSkuList.findIndex(a => a.code == row.code)
      if (b !== -1) {
        data.hasSelectSkuList.splice(b, 1)
      }
    }

    const onsubmit = () => {
      data.checkAll = false
      let selectSkus = data.skuLists.filter(item => item.checked).map(item => ({ ...item, count: 1 }))

      selectSkus.forEach(item => {
        if (!data.hasSelectSkuList.find(a => a.code == item.code)) {
          data.hasSelectSkuList.push(item)
        }
      })

      if (data.hasSelectSkuList.length === 0) {
        ElMessage.warning('暂无优惠劵选择')
        return
      }

      if (data.hasSelectSkuList.length > 1 && props.onlySelectOne) {
        ElMessage.warning('只能选择一个优惠劵')
        return
      }
      if (props.onlySelectOne) {
        handleClose()
      }
      ElMessage.success(props.toastMsg)
    }

    const backtrack = () => {
      handleClose()
    }
    const backtrackNotUpdate = () => {
      ctx.emit('update-dialogVisible', { update: false, selectSkus: data.hasSelectSkuList, isUpdate: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false, selectSkus: data.hasSelectSkuList, isUpdate: true })
    }
    const handleSizeChange = val => {
      data.checkAll = false
      data.pageSize = val
    }
    const handleCurrentChange = val => {
      data.checkAll = false
      data.currentPage = val
    }
    return {
      handleSizeChange,
      handleCurrentChange,
      selectBelowProductSkuArray,
      data,
      handleClose,
      backtrack,
      visible,
      onsubmit,
      indexfn,
      elTable,
      handleCheckAllChange,
      handleCheckAllOne,
      showStore,
    }
  },
})
</script>
<style lang="scss" scoped>
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
</style>
