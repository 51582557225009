/*
 * @Author: your name
 * @Date: 2022-05-05 13:57:11
 * @LastEditTime: 2024-10-24 14:12:11
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/points-manager/service/api.js
 */
import _axios, { post, get, put } from '@/lin/plugin/axios'

class CsMng {

  //分页查询优惠券规则模版列表
  selectCouponPage(data) {
    return post(`/adminChain/coupon/saas/selectCouponPage`, data)
  }
  //会员卡等级
  //分页查询会员等级
  async queryLevels(data) {
    return await post('/adminChain/customer/level/page', data)
  }

  //积分商城
  async pointsGoodsAdd(data) {
    return await post('/back/commodity/pointsGoods/add', data)
  }
  async pointsGoodsQuery(data) {
    return await post('/back/commodity/pointsGoods/query', data)
  }
  async pointsGoodsUpdate(data) {
    return await post('/back/commodity/pointsGoods/update', data)
  }

  async pointsGoodsUpdateSort(data) {
    return await post('/back/commodity/pointsGoods/updateSort', data)
  }
}

export default new CsMng()
